import React from 'react';
import { motion } from 'framer-motion'
import styles from './Trust.module.scss';
import {
    Div, P, svgSprite
} from '../../../../hybrid/wrappers';
import T from '../../../../common/components/T';


const titleVariants = {
    offscreen: {
        y: 200,
        opacity: 0
    },
    onscreen: {
        y: 0,
        opacity: 1,
        transition: { duration: 0.4 }
    }
};

const blockVariants = {
    offscreen: {
        opacity: 0,
        x: 400
    },
    onscreen: custom => ({
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.4,
            delay: custom * 0.2
        }
    })
};

export const Trust = ({ mainStyles }) => {
    const TrustData = [
        [
            {
                num: '10',
                id: 1,
                text: <P>
                    <span>
                        <T textName='trustPageItem-1Span' defaultStr='лет на рынке ' page='/' />
                    </span>
                    <T textName='trustPageItem-1' defaultStr='занимаемся оздоровлением людей' page='/' />
                </P>
            },
            {
                num: '500.000',
                id: 2,
                text: <P>
                    <T textName='trustPageItem-2' defaultStr='положительных отзывов ' page='/' />
                    <br />
                    <span>
                        <T textName='trustPageItem-2Span' defaultStr='от постоянных клиентов' page='/' />
                    </span>
                </P>
            },
            {
                num: '360.000',
                id: 3,
                text: <P>
                    <T textName='trustPageItem-3' defaultStr='банок витаминов ' page='/' />
                    <br />
                    <span>
                        <T textName='trustPageItem-3Span' defaultStr='покупают в год' page='/' />
                    </span>
                </P>
            }
        ],
        [
            {
                num: '6',
                id: 4,
                text: <P>
                    <span>
                        <T textName='trustPageItem-4Span' defaultStr='стран и в 12 прошли документацию ' page='/'
                        />
                    </span>{' '}
                    <br /> <T textName='trustPageItem-4' defaultStr='(в том числе Корея)' page='/' />
                </P>
            },
            {
                num: '3',
                id: 5,
                text: <P>
                    <span>
                        <T textName='trustPageItem-5Span' defaultStr='лабораторных исследования - прошли ' page='/'
                        />
                    </span>
                    <T textName='trustPageItem-5' defaultStr='в США' page='/' />
                </P>

            }
        ]
    ];

    return (
        <motion.div
            initial='offscreen'
            whileInView='onscreen'
            viewport={{ once: true, amount: 0.2 }}
            className={styles.wrapper}>
            <Div className='main-page__container'>
                <motion.h2 className={styles.title} variants={titleVariants}>
                    <T textName='trustPageTitle' defaultStr='почему нам доверяют:' page='/' />
                </motion.h2>
                <Div className={styles.block}
                >
                    {TrustData.map((el, i) => {
                        return (
                            <Div key={i} className={styles.blockLine}>
                                {el.map((item, index) => {
                                    return (
                                        <motion.div variants={blockVariants} custom={item.id} key={index} className={styles.blockItem}>
                                            <Div className={styles.blockItemSvg}>
                                                {svgSprite('trust-check-icon', { style: { ...mainStyles.trustCheckIcon } })}
                                            </Div>
                                            <Div>{item.num}</Div>
                                            {item.text}
                                        </motion.div>
                                    );
                                })}
                            </Div>
                        );
                    })}
                </Div>
            </Div>
        </motion.div>
    );
};