import React from 'react';
import { motion } from 'framer-motion'
import styles from './Community.module.scss';
import T from '../../../../common/components/T';
import { Div, Img } from '../../../../hybrid/wrappers';


const titleVariants = {
    offscreen: {
        x: 200,
        opacity: 0
    },
    onscreen: {
        x: 0,
        opacity: 1,
        transition: { duration: 0.4 }
    }
};

const blockVariantsRight = {
    offscreen: {
        opacity: 0,
        x: 200
    },
    onscreen: custom => ({
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.6,
            delay: custom * 0.3
        }
    })
};
const blockVariantsLeft = {
    offscreen: {
        opacity: 0,
        x: -400
    },
    onscreen: custom => ({
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.4,
            delay: custom * 0.3
        }
    })
};
export const Community = () => {
    const CommunityData = [
        {
            img: '/src/img/main/community/1.webp',
            text: <Div className={styles.communityItemContent}>
                <span>
                    <T textName='communityPageItem-1Span' defaultStr='Кто хочет запустить свой бизнес,' page='/'/>
                </span>{' '}
                <T textName='communityPageItem-1' defaultStr=' но не знает с чего начать' page='/' />
            </Div>
            ,
            motionType: blockVariantsLeft,
            motionId: 1
        },
        {
            img: '/src/img/main/community/2.webp',
            text: <Div className={styles.communityItemContent}>
                <T textName='communityPageItem-2' defaultStr='Кто хочет к основной работе подключить' page='/'/>
                <span>
                    <T textName='communityPageItem-2Span' defaultStr=' дополнительный доход' page='/' />
                </span>
            </Div>,
            motionType: blockVariantsRight,
            motionId: 1
        },
        {
            img: '/src/img/main/community/3.webp',
            text: <Div className={styles.communityItemContent}>
                <span>
                    <T textName='communityPageItem-3Span' defaultStr='Мамы в декрете,' page='/' />
                </span>
                <T textName='communityPageItem-3' defaultStr=' которые хотят работать из дома' page='/' />
            </Div>,
            motionType: blockVariantsLeft,
            motionId: 2
        },
        {
            img: '/src/img/main/community/4.webp',
            text: <Div className={styles.communityItemContent}>
                <span>
                    <T textName='communityPageItem-4Span' defaultStr='Для фрилансеров, которые хотят работать удаленно' page='/'/>
                </span>{' '}
                <T textName='communityPageItems-4' defaultStr='из любой точки мира' page='/' />
            </Div>,
            motionType: blockVariantsRight,
            motionId: 2
        },
        {
            img: '/src/img/main/community/5.webp',
            text: <Div className={styles.communityItemContent}>
                <span>
                    <T textName='communityPageItem-5Span' defaultStr='Для тех, кто хорошо продает,' page='/'/>
                </span>{' '}
                <T textName='communityPageItems-5' defaultStr='но нет качественного товара и ресурсов' page='/'/>
            </Div>,
            motionType: blockVariantsLeft,
            motionId: 3
        },
        {
            img: '/src/img/main/community/6.webp',
            text: <Div className={styles.communityItemContent}>
                <T textName='communityPageItem-6' defaultStr='Для всех желающих,' page='/' />
                <span>
                    <T textName='communityPageItem-6Span' defaultStr=' кто хочет зарабатывать от 300К до 15 миллионов тг.' page='/'/>
                </span>
            </Div>,
            motionType: blockVariantsRight,
            motionId: 3
        }
    ];

    return (
        <motion.div
            initial='offscreen'
            whileInView='onscreen'
            viewport={{ once: true, amount: 0.2 }}
            className={styles.wrapper}>
            <Div className='main-page__container'>
                <motion.h2 variants={titleVariants} className={styles.title}>
                    <T textName='communityPageTitle' defaultStr='для кого подходит этот бизнес' page='/' />
                </motion.h2>
                <Div className={styles.communityWrapper}>
                    {CommunityData.map((el, i) => {
                        return (
                            <motion.div variants={el.motionType} custom={el.motionId} key={i} className={styles.communityItem}>
                                <Img src={el.img} alt={`community-${i}`} className={styles.communityItemImage} />
                                {el.text}
                            </motion.div>
                        );
                    })}
                </Div>
            </Div>
        </motion.div>
    );
};