import React from 'react';
import styles from './Products.module.scss';
import {
    Div, H2, Img, Slider, svgSprite
} from '../../../../hybrid/wrappers';
import T from '../../../../common/components/T';

const ProductsList = [
    {
        img: './src/img/main/products/1.webp',
        name: <T textName='productsPageItemName-1' defaultStr='Масло Кыст Аль-Хинди' page='/' />,
        description: <T textName='productsPageItemDesc-1' defaultStr='100% натуральный продукт природного происхождения  в удобных капсулах для сохранения здоровья на долгие годы' page='/' />,
        link: '/store/product/78'
    },
    {
        img: './src/img/main/products/2.webp',
        name: <T textName='productsPageItemName-2' defaultStr='Масло черного тмина холодного отжима' page='/' />,
        description: <T textName='productsPageItemDesc-2' defaultStr='Секрет силы и здоровья настоящих победителей' page='/' />,
        link: '/store'
    },
    {
        img: './src/img/main/products/3.webp',
        name: <T textName='productsPageItemName-3' defaultStr='Omega-3 - рыбий жир Alhadaya' page='/' />,
        description: <T textName='productsPageItemDesc-3' defaultStr='Универсальный источник поликислот из природного жира рыб семейства лососевых, который воздействует на все функции организма' page='/' />,
        link: '/store'
    },
    {
        img: './src/img/main/products/4.webp',
        name: <T textName='productsPageItemName-4' defaultStr='ASTAXANTHIN' page='/' />,
        description: <T textName='productsPageItemDesc-5' defaultStr='Антиоксиданты играют важную роль в поддержании здоровья, защищая нас от свободных радикалов, которые оказывают повреждающее действие на организм' page='/' />,
        link: '/store'
    },
    {
        img: './src/img/main/products/5.webp',
        name: <T textName='productsPageItemName-5' defaultStr='FICOZAN' page='/' />,
        description: <T textName='productsPageItemDesc-5' defaultStr='Данный продукт создан в помощь Вашему организму противостоять не неблагоприпным условиям среды, а также положительно влиять на течение и исход имеющихся хронических заболеваний.' page='/' />,
        link: '/store'
    },
    {
        img: './src/img/main/products/6.webp',
        name: <T textName='productsPageItemName-6' defaultStr='COLLAGEN' page='/' />,
        description: <T textName='productsPageItemDesc-7' defaultStr='Биологически активная добавка на основе морского коллагена и ламинарии -натуральный продукт, источником сырья для которого служит кожа глубоководных морских рыб, а также ламинария - род морских водорослей' page='/' />,
        link: '/store/product/79'
    },
    {
        img: './src/img/main/products/7.webp',
        name: <T textName='productsPageItemName-7' defaultStr='MEN PERFOMANS-FORCE' page='/' />,
        description: <T textName='productsPageItemDesc-7' defaultStr='Чабрец - универсальная трава в сфере мужского здоровья, снижает отечность мочевыводящих каналов при простатите, тем самым улучшая отток мочи из организма. ' page='/' />,
        link: '/store'
    },
    {
        img: './src/img/main/products/9.webp',
        name: <T textName='productsPageItemName-9' defaultStr='Вiо Elixir' page='/' />,
        description: <T textName='productsPageItemDesc-9' defaultStr='Препарат из многокомпонентного оригинального вещества, обладает противоглистным, противовоспалительным, желчегонным, слабительным, спазмолитическим действиями тормозит развитие свободного радикала.' page='/' />,
        link: '/store/product/122'
    }
];

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <Div >
            <Div className={styles.slickArrowRight} onClick={onClick}>
                {svgSprite('slider-next-arrow', {
                    style: {
                        width: '40px', height: '30px', cursor: 'pointer'
                    }
                })}
            </Div>
        </Div>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <Div >
            <Div className={styles.slickArrowLeft} onClick={onClick}>
                {svgSprite('slider-prev-arrow', {
                    style: {
                        width: '40px', height: '30px', cursor: 'pointer'
                    }
                })}
            </Div>
        </Div>
    );
}

export const Products = () => {
    const settings = {
        dots: false,
        infinite: true,
        // speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        // autoplay: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1660, // Адаптивный брейкпойнт
                settings: { slidesToShow: 2 } // Показать два элемента на меньших экранах
            },
            {
                breakpoint: 1140, // Ещё один адаптивный брейкпойнт
                settings: { slidesToShow: 1 } // Показать один элемент на маленьких экранах
            }
        ]
    };
    return (
        <Div className={styles.wrapper}>
            <Div className='main-page__container'>
                <H2 className={styles.title}>
                    <T textName='productsPageTitle' defaultStr='Наши продукты' page='/' />
                </H2>
                <Slider props={settings}>
                    {ProductsList.map((el, i) => {
                        return (
                            <Div key={i} className={styles.productsItem}>
                                <Img src={el.img} alt={`product-${i}`} />
                                <Div className={styles.productsItemName}>
                                    {el.name}
                                </Div>
                                <Div className={styles.productsItemDesc}>
                                    {el.description}
                                </Div>
                            </Div>
                        );
                    })}
                </Slider>
            </Div>
        </Div>
    );
};