import React, {
    useCallback, useContext, useMemo, useState, useEffect
} from 'react';
import {
    Div,
    Header,
    P,
    A,
    svgSprite,
    Nav,
    Img,
    zendoStorage
} from '../../../hybrid/wrappers';
import Styles from '../../../common/decorators/Styles';
import getStyles from '../../../project/styles/layouts-styles/single-layout-styles';
import UserContext, { UserProfileContext } from '../../../common/utils/getContext';
import { navigate, getData } from '../../../common/utils/router';
import { unscrollableBody, useWindowDimensions } from '../../../common/utils/windowDimensions';
import T from '../../../common/components/T';
import { delToken } from '../../../common/utils/utils';
import LangSwitcherMob from '../../../widgets/langSwitcher/LangSwitcherMobile';
import SingleMenu from '../../../widgets/menu/single/SingleMenuMain';
import CartButtonsMain from '../../store/components/CartButtonsMain';
import { useRouter } from 'next/router';
import RegionAndCurrencySwitcher from '../../../widgets/regionAndCurrencySwitcher/regionAndCurrencySwitcher';
import uikit from '../../../project/uikit/styles';
import { Skeleton } from '../../../widgets/plug/Skeleton';
import MobMenu from '../../../hybrid/library/MobMenu';
import { footerInfo } from '../../store/components/initData';


const SingleHeader = (props) => {
    const { styles } = props;
    const [isFromRef, setIsFromRef] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [cartItems, setCartItems] = useState(null);
    const router = useRouter();

    const active = useRouter().route.replace('/[lang]', '');
    const {
        lang, userToken, setUserToken, domainInfo
    } = useContext(UserContext);

    const { profileData, loaded } = useContext(UserProfileContext) || {};
    const toggleMenu = useCallback((data) => {
        setExpanded(data === false || data === true ? data : !expanded);
    },
    [setExpanded, expanded]);

    const componentConfig = useMemo(() => ({ MenuStyles: styles.WebMenuMain }), [styles.WebMenuMain]);
    const windowDimensions = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(false);
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        if (localStorage.getItem('linkFromRef')) {
            setIsFromRef(localStorage.getItem('linkFromRef'));
        } else {
            if (!userToken && router?.query?.link == 'ref') {
                setIsFromRef(true);
            }
        }
        if (windowDimensions < 1580) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowDimensions, router]);

    unscrollableBody(expanded);

    const currentUrl = router.pathname

    useEffect(() => {
        (async () => {
            const cartId = zendoStorage.get('cart_id');
            if (cartId){
                const cartItems = await getData(
                    `/api/v1/shop/carts/${cartId}/items-count`, userToken, lang, null, null, false, domainInfo?.id
                );
                if (cartItems?.data){
                    setCartItems(cartItems?.data)
                }
            }
        })();
    }, [userToken, lang, domainInfo?.id]);

    const [showMenuUser, setShowMenuUser] = useState(false);
    const [showMenuUserInModal, setShowMenuUserInModal] = useState(false);

    useEffect(() => {
        const container = document.querySelector('body');

        const handleScroll = (e) => {
            setScrollY(e.target.scrollTop);
        };

        container.addEventListener('scroll', handleScroll);
        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const onToTheTopClick = (e) => {
        e.preventDefault()
        const targetElement = document.getElementById('main-page-header');

        if (currentUrl === '/' || currentUrl === '/[lang]'){
            targetElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        } else {
            navigate('/', '/', lang)
        }
    }

    return (
        <Div id={'main-page-header'}>
            <MobMenu expanded={expanded} removeMenu={() => toggleMenu()} styles={componentConfig}>
                <Div
                    styles={{
                        display: 'flex',
                        'align-items': 'center',
                        'justify-content': 'space-between',
                        'margin-bottom': '28px'
                    }}
                >
                    <Div
                        styles={{
                            ...styles.mainMenuLogoMain, height: '44px', cursor: 'pointer'
                        }}
                        data-uitest='77_uitest'
                    >
                        <a href={`/${lang}`} onClick={(e) => onToTheTopClick(e)}>
                            {svgSprite('main-logo-svg', {
                                width: '118',
                                height: '44',
                                ...styles.mainMenuLogoImg
                            })}
                        </a>
                    </Div>
                    <Div
                        styles={styles.mainMenuHamburgerNativeActiveMain}
                        effects={{ hover: styles.mainMenuHamburgerNativeActiveMainHover }}
                        data-uitest='76_uitest'
                        onClick={() => toggleMenu()}
                    >
                        {svgSprite(expanded ? 'burgerClose' : 'burgerMain', {
                            width: '24',
                            height: '24'
                        })}
                    </Div>
                </Div>

                {!profileData && <Div styles={{
                    display: 'flex',
                    'margin-bottom': '20px',
                    'flex-direction': 'column',
                    'align-items': 'center'
                }}>
                    <Div styles={{ 'margin-bottom': '10px' }}>
                        <A
                            title={<T textName='menuActionItemRegister' defaultStr='Регистрация' page='/' />}
                            href={'/[lang]/authentication/[type]'}
                            as={`/${lang}/authentication/register`}
                            styles={{
                                ...styles.mainMenuBtn,
                                ...styles.mainMenuBtnSuccess,
                                width: '226px'
                            }}
                            effects={{ hover: styles.mainMenuBtnSuccessHover }}
                        />
                    </Div>
                    <Div>
                        <A
                            title={<T textName='menuActionItemLogin' defaultStr='Вход' page='/' />}
                            href={'/[lang]/authentication/[type]'}
                            as={`/${lang}/authentication/login`}
                            styles={{
                                ...styles.mainMenuBtn,
                                ...styles.mainMenuBtnOutline,
                                width: '226px'
                            }}
                            effects={{ hover: styles.mainMenuBtnOutlineHover }}
                        />
                    </Div>
                </Div>
                }
                <Div styles={styles.menuScroll}>
                    <Div styles={styles.menuWrapper}>
                        <Div
                            styles={{
                                ...styles.wrapLinksMenu,
                                ...styles.flexColumn,
                                ...styles.dFlex
                            }}
                        >
                            {profileData && <Div styles={{ 'justify-content': 'flex-end', 'font-family': 'Inter' }}>
                                <Div
                                    styles={{
                                        ...styles.wrapperItem,
                                        'justify-content': 'space-between',
                                        border: 'none'
                                    }}
                                    onClick={() => {
                                        setShowMenuUserInModal((pre) => !pre);
                                    }}
                                >
                                    <Div styles={{ display: 'flex' }}>
                                        <Div styles={{ ...styles.singleImageMain, margin: '0 12px 0 0' }}>
                                            {!profileData?.image?.links?.preview ?
                                                svgSprite('plug-user-image', {
                                                    width: '80px',
                                                    height: '80px'
                                                })
                                                :
                                                <Img
                                                    styles={styles.singleImageItemMain}
                                                    src={profileData?.image?.links?.preview}
                                                />
                                            }
                                        </Div>
                                        <Div
                                            styles={{
                                                display: 'flex',
                                                'justify-content': 'center',
                                                'flex-direction': 'column'
                                            }}
                                        >
                                            <Div
                                                styles={{
                                                    'margin-right': '12px',
                                                    display: 'flex',
                                                    'align-items': 'center',
                                                    'font-weight': 600,
                                                    'font-size': '18px',
                                                    'line-height': '22px',
                                                    color: '#231F20'
                                                }}
                                            >
                                                {!profileData.last_name && !profileData.first_name ?
                                                    <Div>{profileData.username}</Div>
                                                    :
                                                    <Div>
                                                        {profileData.last_name} {profileData.first_name}
                                                    </Div>
                                                }
                                            </Div>
                                            <Div
                                                styles={{
                                                    'font-style': 'italic',
                                                    'font-weight': 300,
                                                    'font-size': '12px',
                                                    'line-height': '15px',
                                                    color: '#80828C'
                                                }}
                                            >
                                                <T textName='mobMenuUser' defaultStr='пользователь' page='all' />
                                            </Div>
                                        </Div>
                                    </Div>
                                    <Div styles={styles.mobMenuBtn}>
                                        {svgSprite('mob-menu-dots', {
                                            style: {
                                                width: 4,
                                                height: 12
                                            }
                                        })}
                                    </Div>
                                </Div>
                                <Div
                                    styles={{
                                        'text-align': 'left',
                                        'margin-top': '24px',
                                        'margin-left': '23px'
                                    }}
                                    onClick={async () => await delToken(userToken, lang, setUserToken)}
                                >
                                    <P
                                        styles={{
                                            'font-weight': 400,
                                            'font-size': '1.25rem',
                                            'line-height': 1,
                                            color: '#BCA57F',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <T textName='logoutTitle' defaultStr='Выйти' page='all' />
                                    </P>
                                </Div>

                                {showMenuUserInModal && <Div>
                                    <Div styles={{ ...styles.modalLangListDesktopMain, 'z-index': 1 }}>
                                        <Div
                                            styles={{
                                                padding: '12px',
                                                'border-bottom': '1px solid rgb(128, 130, 140, 0.1)'
                                            }}
                                            onClick={() => {
                                                navigate('/[lang]/office', '/office', lang);
                                            }}
                                        >
                                            <P styles={styles.menuLangSwitcherInfoDesktopMain}>
                                                <T
                                                    textName='menuLangSwitcherInfoDesktop'
                                                    defaultStr='Личный кабинет'
                                                    page='/'
                                                />
                                            </P>
                                        </Div>
                                        <Div
                                            styles={{ padding: '12px' }}
                                            onClick={async () => await delToken(userToken, lang, setUserToken)}
                                        >
                                            <P styles={styles.menuLangSwitcherInfoDesktopMain}>
                                                <T textName='logoutTitle' defaultStr='Выйти' page='all' />
                                            </P>
                                        </Div>
                                    </Div>
                                </Div>
                                }
                            </Div>
                            }
                            <Div styles={{ 'margin-top': '42px' }}>
                                <Div styles={{ ...styles.mainWidgetCart, 'margin-bottom': '50px' }}>
                                    <CartButtonsMain
                                        styles={styles}
                                        isMobile={isMobile}
                                        cartItems={cartItems}
                                    />
                                </Div>
                                <SingleMenu styles={styles} isMobile={isMobile} active={active} />
                            </Div>
                        </Div>
                    </Div>
                </Div>
                {windowDimensions <= 1580 && <LangSwitcherMob page='mainPage' />}
                {windowDimensions <= 1580 && <Div
                    styles={{
                        ...styles.footerSocialMain,
                        'justify-content': 'center',
                        'margin-top': '30px'
                    }}
                >
                    {footerInfo.social.map((elem, idx) =>
                        <React.Fragment key={idx}>
                            <Div styles={styles.footerSocialIconMainItem}>
                                <Div
                                    key={`social${idx}`}
                                    styles={styles.footerSocialIconMain}
                                    data-uitest='70_uitest'
                                    onClick={() => {
                                        window.open(elem.link, '_blank');
                                    }}
                                    effects={{ hover: { ...styles.footerSocialIconMainHover } }}
                                >
                                    {svgSprite(elem.icon, {
                                        style: {
                                            width: elem.width,
                                            height: elem.height,
                                            fill: 'none'
                                        }
                                    })}
                                </Div>
                            </Div>
                        </React.Fragment>)}
                </Div>
                }
            </MobMenu>
            <Header
                styles={{
                    ...styles.fullWidth,
                    ...styles.headerSticky,
                    ...styles.mainHeadTransition,
                    ...scrollY > 0 || windowDimensions <= 768 ? styles.headerShadow : {},
                    'background-color': scrollY === 0 && windowDimensions > 768
                        ? 'rgba(255, 255, 255, 1)'
                        : 'rgba(255, 255, 255, 1)'
                }}
            >
                <Div styles={styles.blockXl}>
                    <Div styles={styles.mainContainerWeb}>
                        <Div styles={styles.mainHeader}>
                            <Nav styles={styles.mainMenuWrap}>
                                {!expanded ?
                                    <>
                                        <Div
                                            styles={styles.mainMenuHamburgerNativeActiveMain}
                                            effects={{ hover: styles.mainMenuHamburgerNativeActiveMainHover }}
                                            data-uitest='76_uitest'
                                            onClick={() => toggleMenu()}
                                        >
                                            {svgSprite(expanded ? 'burgerClose' : 'burgerMain', {
                                                width: '24',
                                                height: '24'
                                            })}
                                        </Div>
                                        {/*<Div onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} style={{width:"20px"}}> hfddhf</Div>*/}
                                        <Div
                                            styles={{ ...styles.mainMenuLogoMain, cursor: 'pointer' }}
                                            data-uitest='77_uitest'
                                            onClick={onToTheTopClick}
                                        >
                                            <a href={`/${lang}`} onClick={(e) => onToTheTopClick(e)}>
                                                {svgSprite('main-logo-svg', {
                                                    width: '118',
                                                    height: '44',
                                                    ...styles.mainMenuLogoImg
                                                })}
                                            </a>
                                        </Div>
                                    </>
                                    : null}
                                <Div styles={styles.mainMenuRight}>
                                    <Div styles={{ ...styles.webMenuBlockMain, 'flex-direction': 'row' }}>
                                        <SingleMenu styles={styles} isMobile={isMobile} active={active} />
                                    </Div>
                                    <Div styles={styles.langSwitcherRight}>
                                        {windowDimensions > 767 && <RegionAndCurrencySwitcher page='mainPage' />}
                                        <Skeleton
                                            wait={Boolean(loaded)}
                                            wrapperStyles={{ width: '100px', ...uikit.ml2 }}
                                            elements={[{ type: 'title', gap: 0 }]}
                                        >
                                            {profileData ?
                                                <Div styles={{ position: 'relative' }}>
                                                    <Div
                                                        styles={styles.widgetUser}
                                                        data-uitest='78_uitest'
                                                        onClick={() => {
                                                            setShowMenuUser((pre) => !pre);
                                                        }}
                                                    >
                                                        <Div styles={styles.singleImage}>
                                                            {!profileData?.image?.links?.preview ?
                                                                svgSprite('plug-user-image', {
                                                                    width: '24px',
                                                                    height: '24px'
                                                                })
                                                                :
                                                                <Img
                                                                    styles={styles.singleImageItem}
                                                                    src={profileData?.image?.links?.preview}
                                                                />
                                                            }
                                                        </Div>
                                                        <Div
                                                            styles={{
                                                                'margin-right': '12px',
                                                                'line-height': '1.06',
                                                                'text-transform': 'capitalize'
                                                            }}
                                                        >
                                                            {!profileData.last_name && !profileData.first_name ?
                                                                <Div>{profileData.username}</Div>
                                                                :
                                                                <>
                                                                    <Div>{profileData.last_name}</Div>
                                                                    <Div>{profileData.first_name}</Div>
                                                                </>
                                                            }
                                                        </Div>
                                                        <Div>
                                                            {svgSprite('angle-left', {
                                                                style: {
                                                                    width: 5,
                                                                    height: 10,
                                                                    fill: styles.variable.greySuperDarkColor,
                                                                    transform: 'rotateZ(-90deg)'
                                                                }
                                                            })}
                                                        </Div>
                                                    </Div>
                                                    {showMenuUser && <Div
                                                        styles={{
                                                            ...styles.modalLangListWrap,
                                                            ...styles.modalUserListWrap,
                                                            'z-index': 1
                                                        }}
                                                    >
                                                        {svgSprite('arrow-dropdown-up', { style: styles.modalLangListArrow })}
                                                        <Div styles={styles.modalLangListDesktop}>
                                                            <Div
                                                                styles={{
                                                                    ...styles.modalLangListItemDesktop,
                                                                    ...styles.modalLangListItemFirst
                                                                }}
                                                                data-uitest='79_uitest'
                                                                onClick={() => {
                                                                    navigate('/[lang]/office', '/office', lang);
                                                                }}
                                                                effects={{ hover: styles.menuIconUserHover }}
                                                            >
                                                                <P styles={styles.menuIconUser}>
                                                                    {svgSprite('user', {
                                                                        width: '16px',
                                                                        height: '16px',
                                                                        fill: 'inherit'
                                                                    })}
                                                                </P>
                                                                <P styles={styles.menuLangSwitcherInfoDesktop}>
                                                                    <T
                                                                        textName='menuLangSwitcherInfoDesktop'
                                                                        defaultStr='Личный кабинет'
                                                                        page='/'
                                                                    />
                                                                </P>
                                                            </Div>
                                                            <Div
                                                                styles={{
                                                                    ...styles.modalLangListItemDesktop,
                                                                    ...styles.modalLangListItemFirst
                                                                }}
                                                                data-uitest='80_uitest'
                                                                onClick={async () =>
                                                                    await delToken(userToken, lang, setUserToken)
                                                                }
                                                                effects={{ hover: styles.menuIconUserHover }}
                                                            >
                                                                <P styles={styles.menuIconUser}>
                                                                    {svgSprite('logout', {
                                                                        width: '16px',
                                                                        height: '16px',
                                                                        fill: 'inherit'
                                                                    })}
                                                                </P>
                                                                <P styles={styles.menuLangSwitcherInfoDesktop}>
                                                                    <T textName='logoutTitle' defaultStr='Выйти' page='all' />
                                                                </P>
                                                            </Div>
                                                        </Div>
                                                    </Div>
                                                    }
                                                </Div>
                                                : !(isFromRef && !userToken)
                                                    ?
                                                    <Div styles={{ ...styles.webMenuBlockButtonsMain }}>
                                                        <Div styles={{ ...styles.webMenuBlockButtonsMainItem, 'padding': '0 12px 0 20px' }}>
                                                            <A
                                                                title={
                                                                    <T
                                                                        textName='menuActionItemRegister'
                                                                        defaultStr='Регистрация'
                                                                        page='/'
                                                                    />
                                                                }
                                                                href={'/[lang]/authentication/[type]'}
                                                                as={`/${lang}/authentication/register`}
                                                                styles={{ ...styles.mainMenuBtn, ...styles.mainMenuBtnSuccess }}
                                                                effects={{ hover: styles.mainMenuBtnSuccessHover }}
                                                            />
                                                        </Div>
                                                        <Div styles={{ ...styles.webMenuBlockButtonsMainItem, 'padding': '0 12px 0 0' }}>
                                                            <A
                                                                title={
                                                                    <T textName='menuActionItemLogin' defaultStr='Вход' page='/' />
                                                                }
                                                                href={'/[lang]/authentication/[type]'}
                                                                as={`/${lang}/authentication/login`}
                                                                styles={{ ...styles.mainMenuBtn, ...styles.mainMenuBtnOutline }}
                                                                effects={{ hover: styles.mainMenuBtnOutlineHover }}
                                                            />
                                                        </Div>
                                                    </Div>
                                                    : null}
                                            <Div styles={styles.mainWidgetCart}>
                                                <CartButtonsMain
                                                    styles={styles}
                                                    cartItems={cartItems}
                                                />
                                            </Div>
                                        </Skeleton>
                                    </Div>
                                </Div>
                            </Nav>
                        </Div>
                    </Div>
                </Div>
            </Header>
        </Div>
    );
};

export default Styles(SingleHeader, getStyles);