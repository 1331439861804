import React from 'react';
import styles from './Production.module.scss';
import T from '../../../../common/components/T';
import {
    Div, H2, Img
} from '../../../../hybrid/wrappers';

export const Production = () => {
    const ProductionData = [
        {
            boldText: <T textName='productionPageBoldText-1' defaultStr='13.500 кв.м.' page='/' />,
            text: <T textName='productionPageText-1' defaultStr='производства высоких стандартов' page='/'/>
        },
        {
            boldText: <T textName='productionPageBoldText-2' defaultStr='3 завода' page='/' />,
            text: <T textName='productionPageText-2' defaultStr='Хабаровск, Новосибирск, Шымкент' page='/'/>
        },
        {
            boldText: <T textName='productionPageBoldText-3' defaultStr='1 миллион' page='/' />,
            text: <T textName='productionPageText-3' defaultStr='Банок в день - мощность изготовления заводов' page='/'/>
        }
    ];

    return (
        <Div className={styles.wrapper}>
            <Div className='main-page__container'>
                <Div className={styles.innerWrapper}>
                    <Div className={styles.leftBlock}>
                        <H2 className={styles.title}>
                            <T textName='productionPageTitle' defaultStr='собственное производство' page='/' />
                        </H2>
                        <Div className={styles.leftBlockItems}>
                            {ProductionData.map((el, i) => {
                                return (
                                    <Div key={i} className={styles.leftBlockItem}>
                                        <Div className={styles.leftBlockItemBold}>
                                            {el.boldText}
                                        </Div>
                                        <p>
                                            {el.text}
                                        </p>
                                        <Div className={styles.leftBlockItemNumber}>{`0${i + 1}`}</Div>
                                    </Div>
                                );
                            })}
                        </Div>
                    </Div>
                    <Div className={styles.rightBlock}>
                        <Div className={styles.galleryFirstLine}>
                            <Img src='./src/img/main/other/gallery-1.webp' alt='gallery-1' />
                            <Img src='./src/img/main/other/gallery-2.webp' alt='gallery-2' />
                        </Div>
                        <Div className={styles.gallerySecondLine}>
                            <Img src='./src/img/main/other/gallery-3.webp' alt='gallery-3' />
                            <Img src='./src/img/main/other/gallery-4.webp' alt='gallery-4' />
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Div>
    );
};