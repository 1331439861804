import React, {
    useEffect, useMemo, useState
} from 'react';
import { motion } from 'framer-motion'
import styles from './Partners.module.scss';
import {
    Div, Slider, svgSprite
} from '../../../../hybrid/wrappers';
import T from '../../../../common/components/T';
import VideoPlayer from '../President/player';
import { useWindowDimensions } from '../../../../common/utils/useWidthDimensions';


const titleVariants = {
    offscreen: {
        x: 300,
        opacity: 0
    },
    onscreen: {
        x: 0,
        opacity: 1,
        transition: { duration: 0.4 }
    }
};
// const variantLeftArrow = {
//     offscreen: { x: -300 },
//     onscreen: {
//         x: 0,
//         transition: { duration: 1 }
//     }
// };
// const variantRightArrow = {
//     offscreen: { x: 300 },
//     onscreen: {
//         x: 0,
//         transition: { duration: 1 }
//     }
// };

const blockVariants = {
    offscreen: {
        opacity: 0,
        scale: 0.5
    },
    onscreen: {
        opacity: 1,
        scale: 1,
        transition: {
            duration: 1,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01]
        }
    }
};
export const Partners = () => {
    const PartnersData = [
        {
            img: '/src/img/main/partners/1.webp',
            name: <T textName='partnersPageItemName-1' defaultStr='Инкара Казакеева' page='/' />,
            text: <>
                <span>
                    <T textName='partnersPageItem-1Span' defaultStr='Результат: 1,5 млн тенге за 1-й месяц работы' page='/'/>
                </span>
                <T textName='partnersPageItem-1' defaultStr=' и стала лидером компании Alhadaya' page='/'/>
            </>,
            link: 'https://www.youtube.com/watch?v=YxMEBORBVnE',
            isClone: false
        },
        {
            img: '/src/img/main/partners/2.webp',
            name: <T textName='partnersPageItemName-2' defaultStr='Аскар Джолмуханов' page='/' />,
            text: <span>
                <T textName='partnersPageItem-2Span' defaultStr='Результат: За 2 недели работы достиг до заработка 400.000 тг' page='/'/>
            </span>,
            link: 'https://www.youtube.com/watch?v=rTZv1G4GCrs',
            isClone: false
        },
        {
            img: '/src/img/main/partners/3.webp',
            name: <T textName='partnersPageItemName-3' defaultStr='Анара Тугельбай' page='/' />,
            text: <span>
                <T textName='partnersPageItem-3Span' defaultStr='Результат: ТОП-лидер, заработала 500.000 тенге за 1-й месяц работы' page='/'/>
            </span>,
            link: 'https://www.youtube.com/watch?v=aGKmx9tOlUQ',
            isClone: false
        }

    ];

    // const [currentSlideNum, setCurrentSlideNum] = useState(0)
    const [slidesCount, setSlidersCount] = useState(1)
    const widthDimensions = useWindowDimensions()
    const oneSlide = useMemo(() => widthDimensions < 1140, [widthDimensions]);

    useEffect(() => {
        if (oneSlide) {
            setSlidersCount(2)
        } else {
            setSlidersCount(1)
        }
    }, [oneSlide])
    function SampleNextArrow(props) {
        const { currentSlide, onClick } = props;

        return (
            <Div >
                <Div className={`${styles.slickArrowRight} ${currentSlide === slidesCount ? styles.disabledArrow : ''}`} onClick={onClick}>
                    {svgSprite('slider-next-arrow', {
                        style: {
                            width: '40px', height: '30px', cursor: 'pointer'
                        }
                    })}
                </Div>
            </Div>
        );
    }

    function SamplePrevArrow(props) {
        const { currentSlide, onClick } = props;

        return (
            <Div >
                <Div className={`${styles.slickArrowLeft} ${currentSlide === 0 ? styles.disabledArrow : ''}`} onClick={onClick}>
                    {svgSprite('slider-prev-arrow', {
                        style: {
                            width: '40px', height: '30px', cursor: 'pointer'
                        }
                    })}
                </Div>
            </Div>
        );
    }


    const settings = {
        dots: true,
        infinite: false,
        // speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        // autoplay: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1660, // Адаптивный брейкпойнт
                settings: { slidesToShow: 2 } // Показать два элемента на меньших экранах

            },
            {
                breakpoint: 1140, // Ещё один адаптивный брейкпойнт
                settings: { slidesToShow: 1 } // Показать один элемент на маленьких экрана
            }
        ]
    };

    const [openedVideos, setOpenedVideos] = useState([])
    // const [openedVideosSlider, setOpenedVideosSlider] = useState([])
    const [isSliderVideoOpened, setIsSliderVideoOpened] = useState(false)


    const onOpenVideo = (id) => {
        if (!openedVideos.includes(id)){
            setOpenedVideos(prev => [...prev, id])
        }
    }
    // const onOpenSliderVideo = (id) => {
    //     if (!openedVideosSlider.includes(id)){
    //         setOpenedVideosSlider(prev => [...prev, id])
    //     }
    // }

    return (

        <motion.div className={styles.wrapper}
            initial='offscreen'
            whileInView='onscreen'
            viewport={{ once: true, amount: 0.3 }}
        >
            <div className='main-page__container'>
                <motion.h2 variants={titleVariants} className={styles.title}>
                    <T textName='partnersPageTitle' defaultStr='посмотри результаты наших бизнес-партнёров' page='/'/>
                </motion.h2>
                <div className={styles.partnersWrapper}>
                    {PartnersData.map((el, i) => {
                        const openedVideo = openedVideos.includes(i)
                        return (
                            <motion.div variants={blockVariants} key={i} className={styles.partnersItem}>
                                {openedVideo
                                    ? <Div className={styles.partnersItemVideo}>
                                        <VideoPlayer autoplay={true} videoSrc={el.link} />
                                    </Div>
                                    : <img onClick={() => onOpenVideo(i)} src={el.img} alt={`partners-${i}`} />}
                                <div className={styles.partnersItemName}>{el.name}</div>
                                <div className={styles.partnersItemText}>{el.text}</div>
                                <div onClick={() => onOpenVideo(i)} className={styles.partnersItemButton}>
                                    <a className={'main-button'} >
                                        <T textName='partnersPageButton' defaultStr='Смотреть видеоотзыв' page='/' />
                                    </a>
                                </div>
                            </motion.div>
                        );
                    })}
                </div>
                <motion.div className={styles.partnersWrapperSlider}
                    initial='offscreen'
                    whileInView='onscreen'
                    viewport={{ once: true, amount: 0.3 }}
                >
                    <Slider props={settings}>
                        {PartnersData.map((el, i) => {
                            return (
                                <motion.div variants={blockVariants} key={i} className={styles.partnersItem}>
                                    <img src={el.img} alt={`partners-${i}`} />
                                    <div className={styles.partnersItemName}>{el.name}</div>
                                    <div className={styles.partnersItemText}>{el.text}</div>
                                    <div onClick={() => setIsSliderVideoOpened(el)} className={styles.partnersItemButton}>
                                        <a className={'main-button'} >
                                            <T textName='partnersPageButton' defaultStr='Смотреть видеоотзыв' page='/' />
                                        </a>
                                    </div>
                                </motion.div>
                            );
                        })}
                    </Slider>
                </motion.div>
                {isSliderVideoOpened && isSliderVideoOpened?.link ?
                    <Div className={styles.partnersItemVideoModal}>
                        <div className={styles.partnersItemVideoModalBlock}>
                            <Div className={styles.partnersItemVideo}>
                                <VideoPlayer autoplay={true} videoSrc={isSliderVideoOpened.link} />
                            </Div>
                            <div onClick={() => setIsSliderVideoOpened(false)} className={styles.partnersItemButton}>
                                <a className={'main-button'} >
                                    <T textName='partnersPageButtonClose' defaultStr='Закрыть' page='/' />
                                </a>
                            </div>
                        </div>
                    </Div>
                    : null}

            </div>
        </motion.div>
    );
};