import { media } from '../../../common/utils/utils';

const stylesheets = (width, height, variable = {}) => ({
    mainPageWrapper: media(width, height, 'w', {
        768: {
            position: 'relative',
            'max-width': '1920px',
            width: '100%',
            margin: '0 auto'
        },
        0: {
            position: 'relative',
            'max-width': '1920px',
            width: '100%',
            margin: '0 auto'
        }
    }),
    mainPageContainer: media(width, height, 'w', {
        1726: {
            'max-width': '1504px',
            width: '80%',
            margin: '0 auto'
        },
        1526: {
            width: '90%',
            margin: '0 auto'
        },
        768: {
            padding: '0 16px',
            width: '90%',
            margin: '0 auto'
        },
        0: {
            'max-width': '1504px',
            width: '100%',
            margin: '0 auto',
            padding: '0 20px'
        }
    }),
    bestsellersConteiner: media(width, height, 'w', {
        768: {},
        0: { padding: 0 }
    }),
    mainBtnWrap: {
        position: 'relative',
        display: 'inline-flex',
        'align-items': 'center',
        'justify-content': 'center',
        'min-width': '256px',
        padding: '48px 52px 48px 26px'
    },
    mainBtnWrapBrush: {
        position: 'absolute',
        top: '50%',
        left: '0',
        width: '100%',
        height: 'auto',
        transform: 'translateY(-50%)',
        'pointer-events': 'none'
    },
    mainBtn: media(width, height, 'w', {
        768: {
            position: 'relative',
            'font-weight': '500',
            'font-size': '18px',
            'line-height': '22px',
            'margin-right': '22px',
            color: '#2C4135'
        },
        0: {
            position: 'relative',
            'font-weight': '500',
            'font-size': '17px',
            'line-height': '22px',
            'margin-right': '12px',
            color: '#2C4135'
        }
    }),
    mainBtnWrapArrow: { position: 'relative' },
    mainBtnWrapCircles: {
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)'
    },

    sectionTitle: media(width, height, 'w', {
        768: {
            'font-family': 'Kudryashev',
            'text-align': 'center',
            'font-weight': 500,
            'font-size': '54px',
            'line-height': '82px',
            'letter-spacing': '0.05em',
            'margin-bottom': '28px',
            position: 'relative'
        },
        0: {
            'font-family': 'Kudryashev',
            'text-align': 'center',
            'font-weight': 500,
            'font-size': '36px',
            'line-height': '55px',
            'letter-spacing': '0.05em',
            'margin-bottom': '28px',
            position: 'relative'
        }
    }),
    slickArrow: {
        position: 'absolute',
        top: '210px',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        width: '48px',
        height: '48px',
        background: variable.mainColorAccent,
        'border-radius': '50%',
        cursor: 'pointer',
        'z-index': '1'
    },
    slickArrowHover: { background: `${variable.mainColorAccentHover} !important` },
    slickArrowLeft: media(width, height, 'w', {
        1590: { left: '-80px' },
        0: { left: '0' }
    }),
    slickArrowRight: media(width, height, 'w', {
        1590: { right: '-80px' },
        0: { right: '0' }
    }),
    slickArrowIcon: { width: '18px' },
    slickArrowIconRight: { transform: 'scale(-1, 1)' },

    // MAIN SECTION
    mainSectionWrap: media(width, height, 'w', {
        1580: {
            position: 'relative',
            'padding-top': '210px'
        },
        0: {
            position: 'relative',
            'padding-top': '110px',
            overflow: 'hidden'
        }
    }),
    mainSectionBg: media(width, height, 'w', {
        768: {
            position: 'absolute',
            top: '-210px',
            left: '-154px',
            width: '100%',
            bottom: 0,
            'object-fit': 'cover',
            'object-position': 'center',
            'z-index': '-1',
            'pointer-events': 'none'
        },
        0: {
            position: 'absolute',
            top: '0px',
            left: '-154px',
            width: '214%',
            bottom: 0,
            'object-fit': 'cover',
            'object-position': 'center',
            'z-index': '-1',
            'pointer-events': 'none'
        }
    }),
    mainSectionLeaves: { position: 'absolute' },
    mainSectionLeaves1: media(width, height, 'w', {
        768: {
            top: '78px',
            left: '-80px'
        },
        0: {
            top: '58px',
            left: '-28px',
            'max-width': '70px'
        }
    }),
    mainSectionLeaves2: {
        right: '-168px',
        top: '-30px'
    },
    mainSectionLeaves3: {
        top: '260px', //'392px',
        left: '400px'
    },
    mainSectionLeaves4: {
        top: '518px',
        left: '-132px'
    },
    mainSectionLeaves5: media(width, height, 'w', {
        768: {
            top: '600px',
            left: '67%',
            'z-index': 1
        },
        0: {
            top: '509px',
            right: '-47px',
            'max-width': '78px',
            'z-index': 1
        }
    }),
    mainSectionLeaves6: {
        right: '-110px',
        top: '390px'
    },
    mainSectionLeaves7: media(width, height, 'w', {
        768: {
            right: '414px',
            top: '-8px'
        },
        0: {
            top: '247px',
            left: '0',
            'max-width': '40px'
        }
    }),
    mainFloraImg: media(width, height, 'w', {
        768: {
            position: 'absolute',
            top: 0,
            right: 0,
            'pointer-events': 'none'
        },
        0: { display: 'none' }
    }),
    mainSectionBlock: media(width, height, 'w', {
        1400: {
            display: 'flex',
            'justify-content': 'space-between',
            position: 'relative',
            'padding-bottom': '290px'
        },
        1200: {
            display: 'flex',
            'justify-content': 'space-between',
            position: 'relative',
            'padding-bottom': '100px'
        },
        0: {
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            position: 'relative',
            padding: '0 16px 60px'
        }
    }),
    mainSectionBottom: media(width, height, 'w', {
        1200: {
            'text-align': 'center',
            'margin-bottom': '75px'
        },
        768: {
            'text-align': 'center',
            'margin-bottom': '75px'
        },
        0: { display: 'none' }
    }),
    mainSectionScrollBtn: {
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        'flex-direction': 'column'
    },
    mainSectionScrollBtnText: {
        'text-align': 'center',
        transform: 'rotate(-90deg) translateX(50%)'
    },

    mainSectionCenter: media(width, height, 'w', {
        1200: {
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            'z-index': '-1'
        },
        0: { position: 'relative' }
    }),
    mainSectionLeftText: media(width, height, 'w', {
        768: {
            'font-family': 'Kudryashev',
            'font-size': '42px',
            'line-height': '64px',
            'letter-spacing': '0.05em',
            'margin-bottom': '46px',
            position: 'relative'
        },
        0: {
            'font-family': 'Kudryashev',
            'font-size': '22px',
            'line-height': '33px',
            'letter-spacing': '0.05em',
            'margin-bottom': '26px',
            position: 'relative'
        }
    }),
    mainSectionLeftImgChild: media(width, height, 'w', {
        1400: {},
        768: {
            'max-width': '400px'
            // 'width': '100%',
        },
        0: {
            'max-width': '400px',
            width: '100%'
        }
    }),
    mainSectionLeftImgChildText: media(width, height, 'w', {
        768: {
            'font-family': 'Kudryashev',
            color: '#ccb186',
            'font-size': '100px'
        },
        0: {
            'font-family': 'Kudryashev',
            color: '#ccb186',
            'font-size': '50px'
        }
    }),
    mainSectionCenterLogoImg: media(width, height, 'w', {
        1400: { width: '886px' },
        768: {
            width: '886px',
            'max-width': '500px'
            // 'width': '100%',
        },
        0: {
            'max-width': '500px',
            width: '100%'
        }
    }),
    mainSectionCenterFigureImg: media(width, height, 'w', {
        1400: { width: '692px' },
        768: {
            width: '692px',
            'max-width': '500px'
            // 'width': '100%',
        },
        0: {
            'max-width': '500px',
            width: '100%'
        }
    }),
    mainSectionCenterWomanImg: media(width, height, 'w', {
        1400: {
            width: '1222px',
            height: '600px',
            top: '200px'
        },
        1200: {
            width: 'calc(1222px * 0.5)',
            top: '200px'
        },
        768: {
            width: 'calc(1222px * 0.5)',
            top: '50%'
        },
        0: {
            'max-width': '500px',
            width: '100%'
        }
    }),
    mainSectionRightItemsImg: media(width, height, 'w', {
        1580: {
            display: 'flex',
            'align-items': 'center',
            overflow: 'visible'
        },
        1200: {
            'max-width': '255px',
            display: 'flex',
            'align-items': 'center'
        },
        0: {
            'max-width': '255px',
            display: 'flex',
            'align-items': 'center',
            margin: '0 auto',
            transform: 'translateX(12%)'
        }
    }),
    mainSectionCenterLogo: media(width, height, 'w', {
        768: {
            position: 'absolute',
            top: '-90px',
            left: '50%',
            transform: 'translateX(-50%)',
            'pointer-events': 'none'
        },
        0: { display: 'none' }
    }),
    mainSectionCenterFigure: media(width, height, 'w', {
        1200: {
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            'pointer-events': 'none'
        },
        0: {
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            'pointer-events': 'none',
            width: '100%'
        }
    }),
    mainSectionCenterWoman: media(width, height, 'w', {
        1200: {
            position: 'absolute',
            top: '0',
            left: '50%',
            transform: 'translateX(-50%)',
            'pointer-events': 'none'
        },
        0: { position: 'relative' }
    }),
    mainSectionRight: media(width, height, 'w', {
        768: {
            position: 'relative',
            width: '378px',
            'margin-top': '10px'
        },
        0: {
            position: 'relative',
            'margin-top': '50px',
            'text-align': 'center'
        }
    }),
    mainSectionRightItems: media(width, height, 'w', {
        1200: {
            'text-align': 'right',
            'margin-bottom': '22px'
        },
        768: { 'margin-bottom': '22px' },
        0: { 'margin-bottom': '5px' }
    }),
    mainSectionRightText: media(width, height, 'w', {
        768: {
            'font-family': 'Kudryashev',
            'font-size': '28px',
            'line-height': '43px',
            'margin-bottom': '76px'
        },
        0: {
            'font-family': 'Kudryashev',
            'font-size': '22px',
            'line-height': '33px',
            'margin-bottom': '60px'
        }
    }),

    // BESTSELLER
    bestsellerWrap: media(width, height, 'w', {
        768: {
            position: 'relative',
            'margin-bottom': '90px'
        },
        0: {
            position: 'relative',
            'margin-bottom': '70px'
        }
    }),
    bestselleBg: media(width, height, 'w', {
        768: {
            position: 'absolute',
            'max-width': '100%',
            'object-fit': 'cover',
            'object-position': 'center',
            'pointer-events': 'none',
            'z-index': '-1',
            left: '50%',
            transform: 'translateX(-50%)',
            top: '-246px',
            width: '1920px'
        },
        0: { display: 'none' }
    }),
    productSlideItems: { padding: '55px 0' },
    sectionTitleBestseller: { 'margin-bottom': '0px' },
    productSlideItemsWrap: {
        'max-width': '1400px',
        margin: '0 auto'
    },
    productSlideItem: media(width, height, 'w', {
        1200: {
            position: 'relative',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            width: '360px',
            height: '360px',
            background: '#fff',
            'border-radius': '50%',
            margin: '0 auto',
            cursor: 'pointer'
        },
        320: {
            position: 'relative',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            width: '320px',
            height: '320px',
            background: '#fff',
            'border-radius': '50%',
            margin: '0 auto',
            cursor: 'pointer'
        },
        0: {
            position: 'relative',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            width: '100%',
            height: '320px',
            background: '#fff',
            'border-radius': '50%',
            margin: '0 auto',
            cursor: 'pointer'
        }
    }),
    productSlideImg: media(width, height, 'w', {
        1200: {
            position: 'relative',
            width: '154px',
            height: '360px',
            'max-width': '154px'
        },
        0: {
            position: 'relative',
            width: '154px',
            height: '320px',
            'max-width': '154px'
        }
    }),
    productSlideIcon: {
        position: 'absolute',
        bottom: '-12px',
        right: '22px',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        cursor: 'pointer',
        width: '60px',
        height: '60px',
        'border-radius': '50%',
        background: variable.mainColorAccent,
        transition: '.3s'
    },
    productSlideIconHover: { background: variable.mainColorAccentHover },
    productSlideItemBgHover: { background: '#2C4135' },
    productSlideTitle: media(width, height, 'w', {
        768: {
            'font-size': '20px',
            'line-height': '24px',
            'text-align': 'center',
            'margin-top': '44px'
        },
        0: {
            'font-size': '18px',
            'line-height': '22px',
            'text-align': 'center',
            'margin-top': '28px'
        }
    }),
    bestSellerBtn: media(width, height, 'w', {
        768: {
            display: 'flex',
            'justify-content': 'center',
            'margin-top': '22px'
        },
        0: {
            display: 'flex',
            'justify-content': 'center'
        }
    }),
    // CATEGORY
    categoryWrap: { position: 'relative' },
    sectionTitleCategory: media(width, height, 'w', {
        768: {},
        0: { 'margin-bottom': '60px' }
    }),
    catLogo: media(width, height, 'w', {
        768: {
            position: 'absolute',
            width: '1243px',
            'z-index': '-1',
            'pointer-events': 'none',
            top: '-55px',
            left: '155px',
            'max-width': 'calc(100% - 160px)'
        },
        0: {
            position: 'absolute',
            'max-width': '100%',
            width: '260px',
            'z-index': '-1',
            'pointer-events': 'none',
            top: '58px',
            left: '14px'
        }
    }),
    catElipse1: media(width, height, 'w', {
        768: {
            position: 'absolute',
            'pointer-events': 'none',
            left: 0,
            top: '-384px',
            'z-index': '-2'
        },
        0: { display: 'none' }
    }),
    catElipse2: media(width, height, 'w', {
        768: {
            position: 'absolute',
            'pointer-events': 'none',
            right: 0,
            top: '-990px',
            'z-index': '-2'
        },
        0: { display: 'none' }
    }),
    catLeave1: media(width, height, 'w', {
        768: {
            position: 'absolute',
            top: '28px',
            right: '18%'
        },
        0: { display: 'none' }
    }),
    catLeave2: media(width, height, 'w', {
        768: {
            position: 'absolute',
            top: '21%',
            left: '-7%'
        },
        0: {
            position: 'absolute',
            top: '408px',
            left: '-10px',
            'max-width': '50px'
        }
    }),
    catLeave3: media(width, height, 'w', {
        768: {
            position: 'absolute',
            right: 0,
            top: '28%'
        },
        0: {
            position: 'absolute',
            right: '40px',
            top: '115px',
            'max-width': '66px',
            filter: 'blur(6px)'
        }
    }),
    catLeave4: media(width, height, 'w', {
        768: {
            position: 'absolute',
            left: '120px',
            top: '59%'
        },
        0: {
            position: 'absolute',
            right: '32px',
            top: '456px',
            width: '47px'
        }
    }),
    catLeave5: media(width, height, 'w', {
        768: {
            position: 'absolute',
            right: '62px',
            top: '66%'
        },
        0: {
            position: 'absolute',
            top: 'calc(83% + 10px)',
            width: '30px',
            right: '20px'
        }
    }),
    catFlora: media(width, height, 'w', {
        768: {
            position: 'absolute',
            right: '0',
            top: '3%'
        },
        0: { display: 'none' }
    }),
    slickArrowCat: media(width, height, 'w', {
        768: {
            top: 'auto',
            bottom: '16px',
            left: 'auto',
            right: '0'
        },
        0: {
            top: 'auto',
            bottom: '16px',
            left: 'auto',
            right: '0',
            width: '52px',
            height: '52px'
        }
    }),
    catItems: media(width, height, 'w', {
        1110: {
            display: 'flex',
            'flex-wrap': 'wrap',
            'max-width': '1366px',
            margin: '0 auto'
        },
        0: {}
    }),
    catItemsBlock: media(width, height, 'w', {
        1110: {
            flex: '0 0 33.33333%',
            'max-width': '33.33333%',
            'text-align': 'center'
        },
        768: { display: 'flex' },
        0: { 'text-align': 'center' }
    }),
    catItemsLeft: media(width, height, 'w', {
        1110: {
            'padding-top': '256px',
            display: 'flex',
            'flex-direction': 'column'
        },
        0: {}
    }),
    catItemsCenter: media(width, height, 'w', {
        1110: {},
        0: {}
    }),
    catItemsRight: media(width, height, 'w', {
        1110: {
            'padding-top': '256px',
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-end'
        },
        0: {}
    }),
    catItem: media(width, height, 'w', {
        1110: {
            cursor: 'pointer',
            'margin-bottom': '114px'
        },
        768: {
            display: 'flex',
            'flex-direction': 'column',
            cursor: 'pointer',
            padding: '0 20px',
            flex: '0 0 50%',
            'max-width': '50%',
            'margin-bottom': '40px'
        },
        0: {
            cursor: 'pointer',
            'max-width': '100%',
            'margin-bottom': '30px'
        }
    }),
    catItemBlock: {
        position: 'relative',
        display: 'inline-block',
        'margin-bottom': '18px'
    },
    catItem1: media(width, height, 'w', { 1110: { 'align-self': 'flex-start' } }),
    catItem2: media(width, height, 'w', {
        1110: { 'padding-left': '160px' },
        0: {}
    }),
    catItem3: media(width, height, 'w', {
        1110: { 'margin-bottom': '68px' },
        0: { 'margin-bottom': '30px' }
    }),
    catItem6: media(width, height, 'w', {
        1110: { 'padding-right': '160px' },
        0: {}
    }),
    catImg: media(width, height, 'w', {
        1110: { 'border-radius': '119px' },
        768: {
            'border-radius': '119px',
            width: '100%'
        },
        0: {
            'border-radius': '119px',
            'max-width': '100%',
            width: '342px',
            'margin-left': 'auto',
            'margin-right': 'auto'
        }
    }),
    catImg3: { width: '100%' },
    catImg6: media(width, height, 'w', {
        1110: {
            width: '318px',
            height: '201px'
        },
        0: {}
    }),
    catTitle: media(width, height, 'w', {
        768: {
            'font-size': '20px',
            'line-height': '24px',
            'text-align': 'center',
            color: '#80828C'
        },
        0: {
            'text-align': 'center',
            color: '#80828C',
            'font-size': '18px',
            'line-height': '22px'
        }
    }),

    // BENEFIT
    sectionTitleBenefit: media(width, height, 'w', {
        768: {
            'text-align': 'center',
            'margin-bottom': '66px'
        },
        0: {
            'text-align': 'center',
            'margin-bottom': '40px'
        }
    }),
    benefitLogo: media(width, height, 'w', {
        992: {},
        0: { 'max-width': '350px' }
    }),
    benefitLogoText: media(width, height, 'w', {
        992: {
            color: '#1a3f30',
            'font-size': '80px'
        },
        0: { 'font-size': '40px' }
    }),
    benefitWrap: media(width, height, 'w', {
        768: {
            position: 'relative',
            padding: '112px 0 54px 0',
            'margin-bottom': '90px'
        },
        0: {
            position: 'relative',
            padding: '70px 0 70px 0',
            'margin-bottom': '62px'
        }
    }),
    benefitBg: media(width, height, 'w', {
        992: {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            'object-fit': 'cover',
            'object-position': 'center',
            'border-radius': '0 458px 0 0',
            'pointer-events': 'none',
            'z-index': '-1'
        },
        0: {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            'object-fit': 'cover',
            'object-position': 'center',
            'border-radius': '0px 120px 0px 0px',
            'pointer-events': 'none',
            'z-index': '-1'
        }
    }),
    benefitBtn: media(width, height, 'w', {
        768: {
            display: 'flex',
            'justify-content': 'center',
            'margin-top': '22px'
        },
        0: {
            display: 'flex',
            'justify-content': 'center',
            'margin-top': '56px'
        }
    }),
    benefitItemsBlock: media(width, height, 'w', {
        768: {
            display: 'flex',
            'flex-wrap': 'wrap',
            'margin-left': 'auto',
            'margin-right': 'auto',
            'max-width': '1466px'
        },
        0: {}
    }),
    benefitItem: media(width, height, 'w', {
        1200: {
            flex: '0 0 50%',
            'max-width': '50%',
            padding: '0 70px',
            'margin-bottom': '20px'
        },
        992: {
            flex: '0 0 50%',
            'max-width': '50%',
            padding: '0 20px',
            'margin-bottom': '20px'
        },
        768: {
            flex: '0 0 100%',
            'max-width': '100%',
            padding: '0 20px',
            'margin-bottom': '20px'
        },
        0: {
            flex: '0 0 100%',
            'max-width': '100%',
            'margin-bottom': '45px'
        }
    }),
    benefitItemBlock: media(width, height, 'w', {
        768: { display: 'flex' },
        0: {
            display: 'flex',
            'flex-direction': 'column'
        }
    }),
    benefitItemOdd: media(width, height, 'w', {
        768: { 'text-align': 'right' },
        0: { 'text-align': 'left' }
    }),
    benefitItemEven: { 'text-align': 'left' },
    benefitItemBlockImgOdd: media(width, height, 'w', {
        768: {},
        0: { order: 1 }
    }),
    benefitItemBlockImg: media(width, height, 'w', {
        768: {},
        0: {
            display: 'flex',
            'flex-wrap': 'wrap'
        }
    }),
    benefitItemBlockTextOdd: media(width, height, 'w', {
        768: {},
        0: {
            order: 2,
            'margin-right': '0'
        }
    }),
    benefitItemBlockTextEven: media(width, height, 'w', {
        768: {},
        0: { 'margin-left': '0' }
    }),
    benefitItemBlockText: { 'padding-top': '30px' },
    benefitItemBlockText1: { 'margin-right': '26px' },
    benefitItemBlockText2: { 'margin-left': '26px' },
    benefitItemBlockText3: { 'margin-right': '26px' },
    benefitItemBlockText4: { 'margin-left': '26px' },
    benefitItemTitle: media(width, height, 'w', {
        768: {
            'font-weight': 600,
            'font-size': '20px',
            'line-height': '24px',
            'margin-bottom': '12px'
        },
        0: {
            'font-weight': 600,
            'font-size': '20px',
            'line-height': '24px',
            'margin-bottom': '12px',
            display: 'none'
        }
    }),
    benefitItemTitleImg: media(width, height, 'w', {
        768: { display: 'none' },
        0: {
            display: 'block',
            'padding-top': '54px',
            'margin-left': '24px'
        }
    }),

    benefitItemTitle1: {
        'max-width': '220px',
        'margin-left': 'auto'
    },
    benefitItemTitle2: { 'max-width': '260px' },
    benefitItemTitle3: {
        'max-width': '142px',
        'margin-left': 'auto'
    },
    benefitItemTitle4: { 'max-width': '164px' },
    benefitItemText: {
        'font-size': '18px',
        'line-height': '22px',
        color: '#80828C'
    },
    benefitItemImg: media(width, height, 'w', {
        768: {
            width: '179px',
            height: '233px',
            'border-radius': '119px',
            'box-shadow': '0px 0px 30px 0px rgba(44, 65, 53,0.3)',
            'object-fit': 'cover'
        },
        0: {
            width: '150px',
            'border-radius': '100px',
            'box-shadow': '0px 0px 30px 0px rgba(44, 65, 53,0.3)',
            'object-fit': 'cover'
        }
    }),

    // CERT
    certWrap: {
        position: 'relative',
        'margin-bottom': '24px'
    },
    certFloraImg: media(width, height, 'w', {
        768: {
            position: 'absolute',
            top: '-84px',
            left: '0',
            'pointer-events': 'none'
        },
        0: {
            position: 'absolute',
            top: '-84px',
            left: '0',
            height: '500px',
            'pointer-events': 'none',
            opacity: '0.5'
        }
    }),
    sectionTitleCert: media(width, height, 'w', {
        768: {
            'font-size': '42px',
            'line-height': '64px',
            'max-width': '660px',
            'margin-left': 'auto',
            'margin-right': 'auto',
            'letter-spacing': '0.05em',
            'margin-bottom': '12px'
        },
        0: {
            'font-size': '36px',
            'line-height': '55px',
            'max-width': '610px',
            'margin-left': 'auto',
            'margin-right': 'auto',
            'letter-spacing': '0.05em',
            'margin-bottom': '12px'
        }
    }),
    certItemsWrap: {
        'max-width': '1080px',
        margin: '0 auto'
    },
    certSlideItem: {
        'text-align': 'center',
        padding: '20px 20px 66px 20px'
    },
    certSlideItems: {
        cursor: 'pointer',
        display: 'inline-block',
        filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1))',
        width: '100%'
    },
    certItem: { position: 'relative' },
    certImg: {
        'border-radius': '10px',
        'max-width': '100%'
        // width: '100%',
        // height: 0,
        // 'padding-bottom': '142%'
    },
    certSliderBottom: {
        'max-width': '1020px',
        margin: '0 auto',
        'border-bottom': '1px solid #BCA57F'
    },
    slickArrowLeftCertWrap: {
        position: 'absolute',
        bottom: '0',
        left: '50%',
        'z-index': '1',
        background: '#fff',
        padding: '0 10px 0 20px',
        transform: 'translate(-100%, 50%)'
    },
    slickArrowLeftCert: {
        position: 'static',
        top: 'unset',
        left: 'unset',
        transform: 'unset'
    },
    slickArrowRightCertWrap: {
        position: 'absolute',
        bottom: '0',
        left: '50%',
        'z-index': '1',
        background: '#fff',
        padding: '0 20px 0 10px',
        transform: 'translate(0%, 50%)'
    },
    slickArrowRightCert: {
        position: 'static',
        top: 'unset',
        left: 'unset'
    },

    patentSectionModal: {
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        background: 'rgba(0, 0, 0, .5)',
        position: 'fixed',
        overflow: 'auto',
        'z-index': 999
    },
    patentSectionModalContent: {
        background: '#fff',
        position: 'relative',
        'border-radius': '24px',
        'max-width': '500px',
        padding: '24px',
        'text-align': 'center',
        margin: '30px auto'
    },
    patentSectionModalContentImg: { 'max-width': '100%' },
    modalWindowClose: {
        position: 'absolute',
        top: '20px',
        right: '20px',
        cursor: 'pointer'
    },
    // COMUNITY
    comunityWrap: media(width, height, 'w', {
        768: {
            'padding-top': '92px',
            'margin-bottom': '100px'
        },
        0: {
            'padding-top': '76px',
            'margin-bottom': '70px'
        }
    }),
    comunityBlock: media(width, height, 'w', {
        992: { display: 'flex' },
        0: {
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'center'
        }
    }),
    sectionTitleComunity: media(width, height, 'w', {
        768: {
            position: 'relative',
            'font-size': '42px',
            'line-height': '64px',
            'margin-bottom': '74px'
        },
        0: {
            position: 'relative',
            'font-size': '36px',
            'line-height': '55px',
            'margin-bottom': '50px'
        }
    }),
    sectionTitleComunityLogo: media(width, height, 'w', {
        768: {
            position: 'absolute',
            'pointer-events': 'none',
            'z-index': '-1',
            top: '-32px',
            right: '176px'
        },
        0: {
            position: 'absolute',
            'pointer-events': 'none',
            'z-index': '-1',
            top: '70px',
            left: 0,
            'max-width': '350px'
        }
    }),
    sectionSubTitleComunity: media(width, height, 'w', {
        768: {
            'font-family': 'Inter',
            'font-weight': 300,
            'font-size': '20px',
            'line-height': '24px',
            'letter-spacing': 'normal'
        },
        0: {
            'font-family': 'Inter',
            'font-weight': 300,
            'font-size': '18px',
            'line-height': '22px',
            'letter-spacing': 'normal',
            'margin-top': '30px'
        }
    }),
    comunityBlockLeft: media(width, height, 'w', {
        1400: {
            position: 'relative',
            flex: '0 0 628px',
            'max-width': '628px',
            'margin-right': '152px'
        },
        992: {
            position: 'relative',
            flex: '0 0 500px',
            'max-width': '500px',
            'margin-right': '40px'
        },
        0: {
            position: 'relative',
            'margin-bottom': '40px'
        }
    }),
    comunityLeave1: media(width, height, 'w', {
        768: {
            position: 'absolute',
            top: '-50px',
            left: '150px',
            'z-index': '-1'
        },
        0: {
            position: 'absolute',
            top: '-24px',
            left: '0px',
            'max-width': '30px',
            'z-index': '-1'
        }
    }),
    comunityLeave2: media(width, height, 'w', {
        768: {
            position: 'absolute',
            top: '-10px',
            right: '33px',
            'z-index': 1
        },
        0: {
            position: 'absolute',
            top: '0px',
            right: '15px',
            'max-width': '40px',
            'z-index': 1
        }
    }),
    comunityLeave3: media(width, height, 'w', {
        768: {
            position: 'absolute',
            top: '5px',
            right: '18%',
            'z-index': '-1'
        },
        0: {
            position: 'absolute',
            top: '-22px',
            right: '0',
            'max-width': '46px',
            'z-index': '-1'
        }
    }),
    comunityBlockRight: media(width, height, 'w', {
        1400: {
            flex: 'auto',
            'padding-top': '46px'
        },
        0: { flex: 'auto' }
    }),
    comunityBlockImg: media(width, height, 'w', {
        1400: {
            width: '628px',
            height: '414px',
            'object-fit': 'cover',
            'object-position': 'center',
            'border-radius': '201px',
            overflow: 'hidden',
            position: 'relative',
            'z-index': 0
        },
        0: {
            width: '500px',
            height: '100%',
            'max-width': '100%',
            'object-fit': 'cover',
            'object-position': 'center',
            'border-radius': '201px',
            overflow: 'hidden',
            position: 'relative',
            'z-index': 0
        }
    }),
    comunityItems: {
        display: 'flex',
        'flex-wrap': 'wrap',
        margin: '0 -11px'
    },
    comunityItemBlock: media(width, height, 'w', {
        768: {
            display: 'flex',
            'flex-direction': 'column',
            padding: '0 11px',
            flex: '0 0 50%',
            'max-width': '50%',
            'margin-bottom': '20px'
        },
        0: {
            display: 'flex',
            'flex-direction': 'column',
            padding: '0 11px',
            flex: '0 0 100%',
            'max-width': '100%',
            'margin-bottom': '20px'
        }
    }),
    comunityItem: {
        display: 'flex',
        'flex-direction': 'column',
        'flex-grow': 1,
        padding: '20px 30px',
        background: '#FFFFFF',
        'box-shadow': '4px 4px 20px rgba(0, 0, 0, 0.1)',
        'border-radius': '10px'
    },
    comunityItemIcon: { 'margin-bottom': '20px' },
    comunityItemText: {
        'font-size': '18px',
        'line-height': '22px'
    },
    comunityBtn: {
        position: 'relative',
        display: 'flex',
        'justify-content': 'center',
        'margin-top': '40px'
    },
    mainSectionRightBtn: media(width, height, 'w', {
        1500: {},
        0: {
            display: 'flex',
            'justify-content': 'flex-end'
        }
    }),
    trustCheckIcon: {
        width: '24px',
        height: '24px'
    },
    advantagesLogo: media(width, height, 'w', {
        800: {
            width: '159px',
            height: '59px'
        },
        600: { width: '100px' },
        200: {
            width: '60px',
            marginTop: '-0px'
        }
    }),
    feedbackFormWrapper: {
        // padding: "18px 21px",
        'border-radius': '14px',
        border: '2px solid #fff',
        background: 'transparent',
        // width: "100%",
        height: '64px',
        'margin-bottom': '30px'
        // "border-radius": "10px",
        // fontSize: "18px",
        // fontWeight: 400,
        // lineHeight: "106.5%",
        // color: "#e7e7e7",
    },
    feedbackFormWrapperActive: {
        border: '0px',
        color: '#fff'
    },
    feedbackFormTitle: {
        // margin: "-36px 0 0 -18px !important",
        color: '#fff !important',
        // "font-size": "18px",
        // "font-weight": "600",
        'font-family': 'Montserrat'
    },
    feedbackFormInput: {
        // border: "none",
        color: '#fff',
        outline: 'none',
        background: 'transparent',
        // "margin-top": "0px !important",
        // height: "54px",
        'font-family': 'Montserrat'
        // color: "#fff",
    },
    feedbackFormPlaceholder: {
        'font-family': 'Montserrat',
        color: '#fff'
    },
    feedbackFormPlaceholderFocus: {
        'font-family': 'Montserrat',
        color: '#fff'
    },
    feedbackFormButtonWrap: {
        'border-radius': '70px',
        'font-family': 'Montserrat',
        'font-size': '17px',
        'font-weight': '600',
        background: '#FFF',
        color: '#000',
        height: '73px',
        'margin-bottom': '5px'
    }
});
export default stylesheets;