import React from 'react';
import { motion } from 'framer-motion'
import styles from './President.module.scss';
import T from '../../../../common/components/T';
import { Div, Img } from '../../../../hybrid/wrappers';
import VideoPlayer from './player';

export const President = () => {
    return (
        <Div className={styles.wrapper}>
            <Div className='main-page__container'>
                <motion.h2 className={styles.title} initial={{
                    x: -1000,
                    opacity: 0
                }}
                animate={{
                    x: 0,
                    opacity: 1
                }}
                transition={{
                    delay: 0.1,
                    duration: 1.5
                }}
                >
                    <T textName='presidentPageTitle' defaultStr='Арсен Кусаинов - президент Alhadaya Business' page='/'
                    />
                </motion.h2>
                <Div className={styles.presidentWrapper}>

                    <motion.div className={styles.presidentVideoWrapper}
                        initial={{
                            y: 1000,
                            opacity: 0
                        }}
                        animate={{
                            y: 0,
                            opacity: 1
                        }}
                        transition={{
                            delay: 0.2,
                            duration: 1.5
                        }}
                    >
                        <VideoPlayer videoSrc={'https://www.youtube.com/watch?v=nJDwmpYXxyI'} />
                    </motion.div>
                    <motion.div className={styles.presidentWrapperImage}
                        initial={{
                            x: 1000,
                            opacity: 0
                        }}
                        animate={{
                            x: 0,
                            opacity: 1
                        }}
                        transition={{
                            delay: 0.2,
                            duration: 1.5
                        }}
                    >
                        <Img src='./src/img/main/other/president.png' alt='president' />
                        <Div className={styles.presidentWrapperLink}>
                            <a target='_blank' href='https://www.instagram.com/arsen_kussainov/' rel='noreferrer'>
                                @arsen_kussainov
                            </a>
                        </Div>
                    </motion.div>
                </Div>
            </Div>
        </Div>
    );
};