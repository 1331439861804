// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import Main from '../../layouts/single/Main';
import MainView from '../../views/main/MainView';
import { getUserStaticProps, getLangPaths } from '../../common/utils/utils';

const MainPage = ({ translates }) => {
    return (
        <Main translates={translates} title={'Главная'}>
            <MainView/>
        </Main>
    )
}

// export async function getStaticProps({ params }) {
//     return await getUserStaticProps(params, '/');
// }
export async function getStaticPropsFunction({ params }) {
    return await getUserStaticProps(params, '/');
}
const getStaticProps = getStaticPropsFunction;
export { getStaticProps }

export async function getStaticPaths() {
    const paths = await getLangPaths();
    return {
        paths,
        fallback: true
    }
}
export default MainPage;