import React from 'react';
import { motion } from 'framer-motion'
import styles from './Blogers.module.scss';
import { Div } from '../../../../hybrid/wrappers';
import T from '../../../../common/components/T';
import img1 from '../../../../public/src/img/main/blogers/1.jpg'
import img2 from '../../../../public/src/img/main/blogers/2.jpg'
import img3 from '../../../../public/src/img/main/blogers/3.jpg'
import img4 from '../../../../public/src/img/main/blogers/4.jpg'
import img5 from '../../../../public/src/img/main/blogers/5.jpg'
import img6 from '../../../../public/src/img/main/blogers/6.jpg'
import img7 from '../../../../public/src/img/main/blogers/7.jpg'
import img8 from '../../../../public/src/img/main/blogers/8.jpg'


const titleVariants = {
    offscreen: {
        x: 300,
        opacity: 0
    },
    onscreen: {
        x: 0,
        opacity: 1,
        transition: { duration: 0.4 }
    }
};
const variantQuestionBlock = {
    offscreen: {
        x: -500,
        opacity: 0
    },
    onscreen: custom => ({
        x: 0,
        opacity: 1,
        transition: {
            delay: custom * 0.2,
            duration: 0.5
        }
    })
};
export const Blogers = () => {
    const BlogersData = [
        img1?.src,
        img2?.src,
        img3?.src,
        img4?.src,
        img5?.src,
        img6?.src,
        img7?.src,
        img8?.src
        // 'https://thumb.tildacdn.com/tild3165-6430-4564-b863-366135326663/-/resize/988x/-/format/webp/Frame_18.png',
        // 'https://thumb.tildacdn.com/tild3035-3238-4362-a635-656236323939/-/resize/988x/-/format/webp/Frame_11.png',
        // 'https://thumb.tildacdn.com/tild3636-3335-4762-b436-336537626539/-/resize/988x/-/format/webp/Frame_17.png',
        // 'https://thumb.tildacdn.com/tild6634-6435-4263-a662-366266346266/-/resize/988x/-/format/webp/Frame_13.png',
        // 'https://thumb.tildacdn.com/tild3633-3431-4532-b036-663137336163/-/resize/988x/-/format/webp/Frame_16.png',
        // 'https://thumb.tildacdn.com/tild6264-6333-4738-a531-393064656338/-/resize/988x/-/format/webp/Frame_15.png',
        // 'https://thumb.tildacdn.com/tild3465-3630-4762-a666-336230663531/-/resize/988x/-/format/webp/Frame_14.png',
        // 'https://thumb.tildacdn.com/tild3537-3536-4830-a430-343731613638/-/resize/988x/-/format/webp/Frame_12.png'
    ];

    return (
        <motion.div
            initial='offscreen'
            whileInView='onscreen'
            viewport={{ once: true, amount: 0.1 }}
            className={styles.wrapper}
        >
            <Div className='main-page__container'>
                <motion.h2 variants={titleVariants} className={styles.title}>
                    <T textName='blogersPageTitle' defaultStr='наши действующие партнеры' page='/' />
                </motion.h2>
                <Div className={styles.blogersWrapper}>
                    {BlogersData.map((el, i) => {
                        return <motion.div key={i} custom={i + 1} variants={variantQuestionBlock}>
                            <motion.img src={el} alt={`blogers-${i}`} />
                        </motion.div>;
                    })}
                </Div>
            </Div>
        </motion.div>
    );
};